import React from "react"
import { graphql, PageProps } from "gatsby"

import Layout from "../components/Layouts/mainPage"
import { NewHeader } from "../components/Headers/NewHeader"
import { Banner } from "../components/Banners/CardProcentNaOstatok-v2"
import { WhatAreTheBenefits } from "../components/WhatAreTheBenefits"
import { HowInstallmentWorks } from "../components/HowInstallmentWorks"
import { CardWithMaxBenefits } from "../components/CardWithMaxBenefits"
import { CashbackHalva } from "../components/CashbackHalva"
import { FormPKW } from "../components/FormPKW"
import { NewCalculator } from "../components/NewCalculator/NewCalculator"
import { MoreBenefits } from "../components/MoreBenefits"
import { Partners } from "../components/PartnersNew"
import { Reviews } from "../components/Reviews"
import { Questions } from "../components/Questions"
import { NewFooter } from "../components/NewFooter"

import { getPageData } from "../helpers/getPageData"

import { PageData } from "../interfaces/pageProps"

const newWhatsAreTheBenefitsTetx =
  "Специальные предложения позволяют оформить рассрочку на 10 месяцев без переплат"

const whatsAreTheBenefitsTitle = "10 месяцев рассрочки"

const whatsAreTheBenefitsPrice = "10 747 ₽ / мес."

export default function Page({ data }: PageProps<PageData>) {
  const { ligal, noIndex } = getPageData(data)

  return (
    <Layout noHeader noFooter noIndex={noIndex}>
      <NewHeader />
      <Banner orderNum="1" />
      <CardWithMaxBenefits orderNum="2" />
      <FormPKW dataLayerName="shortPersonalForm" orderNum="3" />
      <HowInstallmentWorks orderNum="4" />
      <NewCalculator orderNum="5" />
      <WhatAreTheBenefits
        text={newWhatsAreTheBenefitsTetx}
        title={whatsAreTheBenefitsTitle}
        price={whatsAreTheBenefitsPrice}
        orderNum="6"
      />
      <CashbackHalva orderNum="7" />
      <MoreBenefits orderNum="8" />
      <Partners orderNum="9" />
      <Reviews orderNum="10" />
      <Questions orderNum="11" />
      <NewFooter ligal={ligal} orderNum="12" />
    </Layout>
  )
}
export const query = graphql`
  query {
    admin {
      page(url: "https://halvacard.ru/order/card-procent-na-ostatok/") {
        url
        ligal {
          text
        }
        notIndex
      }
    }
  }
`
